import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { imagesRepoUrl } from "../../config.json";

export const BannerCardDisplay = (item) => {
  const sectionStyle = {
    backgroundImage: `url(${imagesRepoUrl}${item.bannerurl1})`,
  };
  return (
    <React.Fragment>
      <Card className="place-Card-Banner">
        <Card.Body>
          <Row>
            <Col>
              <div
                className="employeecard-image-banner"
                style={sectionStyle}
              ></div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};
