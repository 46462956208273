import React, { useState, useEffect, Fragment } from "react";
import "./EmployeeGrid.style.scss";
import { useHistory } from "react-router-dom";
import EmployeeGridItem from "../EmployeeGridItem/EmployeeGridItem";
import { Row, Col, Container, Button } from "react-bootstrap";
import { EmployeeDetails } from "../EmployeeDetails/EmployeeDetails";
import { useDispatch } from "react-redux";
import { setSelctedEmployee } from "../../../state/actions/employees";

const EmployeeGrid = ({ filteredEmployees, category, handleShow, show }) => {
  // Set pagination and limit employees 20 per page
  const [currentPage, setCurrentPage] = useState(1);
  const [employeesPerPage] = useState(20);
  let selectedEmployee = "test";
  const history = useHistory();
  const handleSelectedEmployee = (employee) => {
    dispatch(setSelctedEmployee(employee));
    history.push("/employeedetail");
  };
  // Get current employees
  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstPost = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = filteredEmployees.slice(
    indexOfFirstPost,
    indexOfLastEmployee
  );
  const dispatch = useDispatch();

  // Get pages
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredEmployees.length / employeesPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (filteredEmployees.length < 20) {
      setCurrentPage(1);
    }
  }, [filteredEmployees]);

  // Change page
  const paginate = (number) => setCurrentPage(number);

  return (
    <Fragment>
      {!show ? (
        <Fragment>
          <Container>
            <Row>
              {currentEmployees.map((employee, index) => (
                <EmployeeGridItem
                  employee={employee}
                  key={employee.id}
                  index={index}
                  category={category}
                  handleShow={handleShow}
                  handleSelectedEmployee={handleSelectedEmployee}
                />
              ))}
            </Row>
          </Container>
          <div className="pagination">
            <ul>
              {filteredEmployees.length > 20
                ? pageNumbers.map((number) => (
                    <li
                      className={currentPage === number ? "active" : ""}
                      key={number}
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </li>
                  ))
                : null}
              <div className="bar"></div>
            </ul>
          </div>
        </Fragment>
      ) : (
        selectedEmployee !== undefined && (
          <EmployeeDetails selectedEmployee={selectedEmployee} />
        )
      )}
    </Fragment>
  );
};

export default EmployeeGrid;
