import React from "react";
import { Navbar } from "react-bootstrap";
import brandLogo from "../../../img/nsak-logo.png";
import "../NsakLogo/NsakLogo.css";

export const NsakLogo = () => {
  return (
    <div className="brand">
      <Navbar.Brand href="/home" className="brandname">
        <img className="brand-logo" src={brandLogo} alt="ofi films" />
        <div className="png-logo">
          <a href="https://www.osmania.ac.in/" target="_blank">
            <img
              className="brand-name"
              src="images/osmania-university.png"
              alt=""
            />
          </a>
          <a href="https://www.task.telangana.gov.in/" target="_blank">
            <img className="brand-name" src="images/task.png" alt="" />
          </a>
          <a href="https://www.netacad.com/" target="_blank">
            <img className="brand-name" src="images/cisco.png" alt="" />
          </a>
        </div>
      </Navbar.Brand>
    </div>
  );
};
