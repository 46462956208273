import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Home } from "../components/Home/Home";
import { Landing } from "../components/Common/Landing";
import { ProductsList } from "../components/ProductsList/ProductsList";
import { EmployeesList } from "../components/Employees/EmployeesList/EmployeesList";
import NotFound from "./../components/NotFound/NotFound";
import { Contacts } from "../components/Contacts/Contacts";
import { AboutUs } from "../components/AboutUs/AboutUs";
import { Admission } from "../components/Admission/Admission";
import { Events } from "../components/Events/Events";
import { Gallery } from "../components/Gallery/Gallery";
import { Courses } from "../components/Courses/Courses";
import { Placement } from "../components/Placement/Placement";
import { Services } from "../components/Services/Services";
import { NavbarCategory } from "../components/ProductsList/NavbarCategory";
import { ProductDetails } from "../components/ProductDetails/ProductDetails";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/home" component={Home} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/admission" component={Admission} />
        <Route path="/events" component={Events} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/courses" component={Courses} />
        <Route path="/placement" component={Placement} />
        <Route path="/productdetail" component={ProductDetails} />
        <Route path="/services" component={Services} />
        <Route path="/productlist" component={ProductsList} />
        <Route path="/employeelist" component={EmployeesList} />
        <Route path="/NavbarCategory" component={NavbarCategory} />
        <Route path="/Landing" component={Landing} />
      </Switch>
    </Router>
  );
};

export default Routes;
