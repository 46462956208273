import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Row, Navbar } from "react-bootstrap";
import {
  getAllCategories,
  getSelectedCategory,
} from "../../../state/selectors/categories";
import { LeftNavMenu } from "./LeftNavMenu";

export const LeftNavigation = () => {
  const [data, setData] = useState();
  const selector = useSelector(getAllCategories);
  const selectorCategory = useSelector(getSelectedCategory);
  const [showSidebar, setShowSidebar] = useState(true);
  const formik = useFormik({
    initialValues: { currItem: false },
  });
  const showHandler = () => {
    setShowSidebar(!showSidebar);
  };
  useEffect(() => {
    const filterCategoriesData = selector.filter(
      (cat) => cat.ParentId == selectorCategory
    );
    setData(filterCategoriesData);
  }, [selectorCategory]);
  useEffect(() => {
    if (data && data[0] !== undefined) {
      const els = document.getElementById(data[0].CategoryId);
      els && els.click();
    }
  }, [data]);
  return (
    <Row>
      <div className="main_Div Menu">
        <Navbar expand="lg">
          <Navbar.Toggle
            className="navBarToggle"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className={showSidebar ? "navMenu active" : "nav-menu"}>
              <div className="vertical-menu">
                {data &&
                  data.map((item) => {
                    return (
                      <LeftNavMenu
                        allItems={data}
                        currItem={item}
                        showHandler={showHandler}
                        className={`${
                          formik.values[item.CategoryId]
                            ? "activeSuperTabs"
                            : ""
                        }`}
                        to="/"
                        // onClick={() =>
                        //   handleSelectedSuperParentTab(item.CategoryId)
                        // }
                      />
                    );
                  })}
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Row>
  );
};
