import React from "react";
import "../Home/Home.style.css";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

export const AboutNsakSections = () => {
  return (
    <React.Fragment>
      <section>
        <div className="row">
          <div className="col-xl-8">
            <div className="plr2">
              <div className="section-top-padding"> </div>
              <div className="col-12  colHome">
                <div className="title-tech ">ABOUT NSAKCET</div>
              </div>
              <div className="about-nsak-section-p  colHome">
                <p>
                  We are one of the largest, most diverse universities in the
                  USA with over 90,000 students in USA, and a further 30,000
                  studying across 180 countries for Kingster University.
                </p>
              </div>

              <div className="about-nsak-section-p-2">
                <p>
                  Kingster University was established by John Smith in 1920 for
                  the public benefit and it is recognized globally. Throughout
                  our great history, Kingster has offered access to a wide range
                  of academic opportunities. As a world leader in higher
                  education, the University has pioneered change in the sector.
                </p>
              </div>

              <div className="readmore-section">
                <div className="readmore-container">
                  <a
                    class="readmore-button  readmore-transparent readmore-button-no-border"
                    href="#"
                  >
                    <span class="readmore-content">Read More</span>
                    <ArrowRightAltIcon />
                    {/* <i class="gdlr-core-pos-right fa fa-long-arrow-right" id="i_1dd7_0"></i> */}
                  </a>
                </div>
              </div>

              <div className="section-bottom-padding"></div>
            </div>
          </div>
          {/* 
            <div className="col-xl-4">
              <div
                className="video-promo news-section ptb-100 background-img"
                style={{
                  backgroundImage: `url(${require("../../img/tech-bg.jpg")})`,
                }}
              >
                <div className="container plr">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-top-padding"></div>
                      <div className="title-tech2 text-white">Latest News </div>

                      <marquee
                        scrollamount="4%"
                        behavior="scroll"
                        direction="down"
                      >
                        <div className="news-element">
                          <p>Alumini Donor</p>
                        </div>
                        <div className="news-border"></div>

                        <div className="news-element">
                          <p>Athletic Calendar</p>
                        </div>
                        <div className="news-border"></div>

                        <div className="news-element">
                          <p>All Kingster’s Events</p>
                        </div>
                        <div className="news-border"></div>

                        <div className="news-element">
                          <p>Partnership & Out Reach</p>
                        </div>
                        <div className="news-border"></div>

                        <div className="news-element">
                          <p>Academic Programs</p>
                        </div>
                        <div className="news-border"></div>

                        <div className="news-element">
                          <p>Tution And Fees</p>
                        </div>
                        <div className="news-border"></div>
                      </marquee>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
        </div>
      </section>
    </React.Fragment>
  );
};
