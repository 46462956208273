import React from "react";
import { AllTabsComps } from "../AllTabsComps/AllTabsComps";

export const Courses = () => {
  return (
    <React.Fragment>
      <AllTabsComps />
      {/* <div className="section-top-padding"></div>
      <SectionTitle sectionheading="Courses" />
      <Container>
        <CoursesDisplay />
      </Container> */}
    </React.Fragment>
  );
};
