import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useFormik } from "formik";
import { getAllCategories } from "../../../state/selectors/categories";
import { setTextFilter } from "../../../state/actions/filters";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
} from "../../../state/actions/categories";
import "./LeftNavMenu.css";

export const LeftNavMenu = ({ currItem }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { currItem: false },
  });
  const selector = useSelector(getAllCategories);
  const [level3, setLevel3] = useState();
  const [level2, setLevel2] = useState();
  const [level2Change, setLevel2Change] = useState(false);
  const [level2show, setLevel2Show] = useState(false);
  const [level3show, setLevel3Show] = useState(false);

  const handleSubSelectedAutoLevel3 = () => {
    if (level3 && level3[0] !== undefined) {
      const els = document.getElementById(level3[0].CategoryId);
      els && els.click();
    }
  };
  const handleSubSelectedAutoLevel2 = () => {
    if (level2Change && level2Change[0] !== undefined) {
      const els = document.getElementById(level2Change[0].CategoryId);
      els && els.click();
    }
  };
  const handleSelectedCategory = (e) => {
    dispatch(setTextFilter(""));
    const els = document.getElementsByClassName("activeSubTabs");
    els[0] && els[0].click();
    formik.setFieldValue(e.target.id, !formik.values[e.target.id]);
    dispatch(setSelectedCategoryLevel2(e.target.name));
    dispatch(setSelectedCategoryLevel3(e.target.id));
  };
  const handleSelectedParentTab = () => {
    const els = document.getElementsByClassName("activeTabs");
    els[0] && els[0].click();
    formik.setFieldValue(
      currItem.CategoryId,
      !formik.values[currItem.CategoryId]
    );
    setLevel2Change(!level2Change);
  };

  useEffect(() => {
    handleSubSelectedAutoLevel3();
  }, [level3]);

  useEffect(() => {
    handleSubSelectedAutoLevel2();
  }, [level2Change]);
  useEffect(() => {
    const filterCategoriesData = selector.filter(
      (cat) => cat.ParentId == currItem.CategoryId
    );
    setLevel3(filterCategoriesData);
  }, [currItem]);
  return (
    <React.Fragment>
      <div className={`btn-div`}>
        <Nav.Link
          id={currItem.CategoryId}
          className={`${
            formik.values[currItem.CategoryId] ? "activeTabs" : ""
          }`}
          onClick={handleSelectedParentTab}
          variant="light"
        >
          {currItem.CategoryName}
        </Nav.Link>
      </div>
      {formik.values[currItem.CategoryId] && (
        <Nav id="nav-menu">
          {level3.map((level3Cat) => (
            <Nav.Link
              id={level3Cat.CategoryId}
              name={level3Cat.ParentId}
              className={`${
                formik.values[level3Cat.CategoryId] ? "activeSubTabs" : ""
              }`}
              to="/"
              onClick={handleSelectedCategory}
            >
              {level3Cat.CategoryName}
            </Nav.Link>
          ))}
        </Nav>
      )}
    </React.Fragment>
  );
};
