import React, { useState, useEffect } from "react";
import { ControlledCarousel } from "./ControlledCarousel";
import { VideoAffairsDisplay } from "../Common/VideoAffairsDisplay";
import { AboutNsakSections } from "../Common/AboutNsakSections";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { subscriberRegistrationNo } from "../../config.json";
import { LeftNavigation } from "../Common/Employee/LeftNavigation";
import { IDCardCarouselDisplay } from "./MultiEminentProfessorsDisplay/IDCardCarouselDisplay";
import { MultiEventsAndPlacementsDisplay } from "./MultiEventsAndPlacementsDisplay/MultiEventsAndPlacementsDisplay";
import { MultiCurrentAffairsDisplay } from "../MultiCurrentAffairsDisplay/MultiCurrentAffairsDisplay";
import {
  getSelectedCategory,
  getAllCategories,
} from "../../state/selectors/categories";
import { setVideosData } from "../../state/actions/categories";
import { getAffairs } from "../../services/subscriberService";
import { getMultipleFiles } from "../../services/subscriberService";
import { getComponentElement } from "./ControllerComps";

export const Home = () => {
  const selectedCategory = useSelector(getSelectedCategory);
  const selectorCategoriesData = useSelector(getAllCategories);
  const dispatch = useDispatch();
  const [category1, setCategory1] = useState();
  const [category2, setCategory2] = useState();
  const [category3, setCategory3] = useState();
  const [carouselData, setCarouselData] = useState();
  const [eventsData, setEventsData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [quicklinksData, setQuicklinksData] = useState([]);
  const setMultipleFiles = async (
    fileType,
    selectedCategory,
    selectedCategory2,
    selectedCategory3
  ) => {
    const resultImages = await getMultipleFiles(
      fileType,
      selectedCategory,
      selectedCategory2,
      selectedCategory3
    );
    return resultImages;
  };
  const setMultipleFilesAll = async () => {
    if (category3) {
      const resultImagesCarousel = await setMultipleFiles(
        "IMG",
        category1[0].CategoryId,
        category2[0].CategoryId,
        category3[2].CategoryId
      );
      resultImagesCarousel !== undefined &&
        setCarouselData([...resultImagesCarousel.data]);
    }
  };
  const getAllAffairsUI = async () => {
    const result = await getAffairs(
      "51e1a477-8fec-4bf6-a7c0-f7ac04737fe9",
      subscriberRegistrationNo,
      "",
      category1[0].CategoryId,
      category2[0].CategoryId,
      category3[1].CategoryId
    );
    if (result) {
      const data = result.data[0];
      setEventsData([...data]);
    }
    const resultNews = await getAffairs(
      "f40a59c5-7a8f-45b9-84dd-373534eaf346",
      subscriberRegistrationNo,
      "",
      category1[0].CategoryId,
      category2[0].CategoryId,
      category3[1].CategoryId
    );
    if (resultNews) {
      const data = resultNews.data[0];
      setNewsData([...data]);
    }
    const resultQuicklinks = await getAffairs(
      "ca37af00-9636-4ab3-a289-e1094cd9efe1",
      subscriberRegistrationNo,
      "",
      category1[0].CategoryId,
      category2[0].CategoryId,
      category3[1].CategoryId
    );
    if (resultQuicklinks) {
      const data = resultQuicklinks.data[0];
      setQuicklinksData([...data]);
    }
    const resultVideos = await getAffairs(
      "8ffd79f7-68fe-40d3-a21d-373728225159",
      subscriberRegistrationNo,
      "",
      category1[0].CategoryId,
      category2[0].CategoryId,
      category3[1].CategoryId
    );
    if (resultVideos) {
      const data = resultVideos.data[0];
      dispatch(setVideosData([...data]));
    }
  };
  useEffect(() => {
    setMultipleFilesAll();
    if (category3) getAllAffairsUI();
  }, [category3]);
  useEffect(() => {
    if (category2) {
      const filteredSuperMenu =
        selectorCategoriesData &&
        selectorCategoriesData.filter(
          (item) => item.ParentId === category2[0].CategoryId
        );
      if (filteredSuperMenu) setCategory3(filteredSuperMenu);
    }
  }, [category2]);
  useEffect(() => {
    if (category1) {
      const filteredSuperMenu =
        selectorCategoriesData &&
        selectorCategoriesData.filter(
          (item) => item.ParentId === category1[0].CategoryId
        );
      if (filteredSuperMenu.length > 0) setCategory2(filteredSuperMenu);
    }
  }, [category1]);
  useEffect(() => {
    const filteredSuperMenu =
      selectorCategoriesData &&
      selectorCategoriesData.filter(
        (item) =>
          (item.ParentId === null || item.ParentId === 0) &&
          item.isDisplayCategory === 0
      );
    if (filteredSuperMenu) setCategory1(filteredSuperMenu);
  }, [selectorCategoriesData]);
  return (
    <React.Fragment>
      <Row>
        {selectedCategory ? (
          <React.Fragment>
            <Col lg="2">
              <div>
                <LeftNavigation />
              </div>
            </Col>
            <Col lg="10">{getComponentElement(selectedCategory)}</Col>
          </React.Fragment>
        ) : (
          <Col lg="12" className="colHome">
            <Row>
              <Col lg="3" className="colHome">
                {eventsData && eventsData.length > 0 && (
                  <MultiCurrentAffairsDisplay
                    affairsData={quicklinksData}
                    title="Quick links"
                  />
                )}
              </Col>
              <Col lg="9" className="colHome">
                <ControlledCarousel imagesData={carouselData} />
              </Col>
            </Row>
            <Row>
              <Col lg="8" className="colHome">
                <AboutNsakSections />
              </Col>
              <Col lg="4" className="colHome">
                {eventsData && eventsData.length > 0 && (
                  <MultiCurrentAffairsDisplay
                    affairsData={newsData}
                    title="Upcoming News"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="colHome">
                <VideoAffairsDisplay />
              </Col>
            </Row>
            <Row>
              <Col lg={3} className="colHome">
                <IDCardCarouselDisplay />
              </Col>
              <Col lg={6} className="colHome">
                {eventsData && eventsData.length > 0 && (
                  <MultiCurrentAffairsDisplay
                    affairsData={eventsData}
                    title="Upcoming Events"
                  />
                )}
              </Col>
              <Col lg={3} className="colHome">
                <MultiEventsAndPlacementsDisplay />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};
