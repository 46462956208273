import React from "react";
import { AllTabsComps } from "../AllTabsComps/AllTabsComps";

export const Events = () => {
  return (
    <React.Fragment>
      <AllTabsComps />
    </React.Fragment>
  );
};
