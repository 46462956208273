import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SectionTitle } from "../../Common/SectionTitle";
import { FeaturedEmployees } from "../../Common/Employee/FeaturedEmployees";
import { setSelctedEmployee } from "../../../state/actions/employees";

export const FeaturedEmployeesList = () => {
  const [homeDisplayEmployees, setHomeDisplayEmployees] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <SectionTitle sectionheading="Featured Employees" />
      <Container>
        <Row>
          <FeaturedEmployees
            employeeThumbnail="employee-21.jpg"
            employeeboxname="Curtains"
            catId="8"
            employeeboxdescription="Curtain has been transformed in recent years & it's available in a wide range of stylish hanging systems like Eyelet Curtains, American Pleated, Loop Curtains, etc."
          />

          <FeaturedEmployees
            employeeThumbnail="employee-7.jpg"
            employeeboxname="Mattress"
            catId="15"
            employeeboxdescription="We deals in all type of Mattress from Homemade(made by us) to the Branded one which includes Kurlon, Sleepwell."
          />

          <FeaturedEmployees
            employeeThumbnail="employee-8.jpg"
            employeeboxname="Sofa"
            catId="12"
            employeeboxdescription="We stock some Readymade Sofa at our Store & make customize Sofa as per the requirement. We also do Cleaning & Repairing of Sofa."
          />
          <FeaturedEmployees
            employeeThumbnail="employee-9.jpg"
            employeeboxname="Wallpaper"
            employeeboxdescription="Wallpaper is a kind of material used to cover and decorate the interior walls of homes, offices, cafes, etc. it is one aspect of interior decoration."
            catId="16"
          />
          <FeaturedEmployees
            employeeThumbnail="employee-10.jpg"
            employeeboxname="Blinds"
            catId="10"
            employeeboxdescription="We deals in all type of Blinds from Local to the Branded one. There are various types of Blinds like Vertical, Roman, Roller, Chick, etc. as per the need."
          />
          <FeaturedEmployees
            employeeThumbnail="employee-11.jpg"
            employeeboxname="Services"
            catId="14"
            employeeboxdescription="Services of Flooring are available in a variety of designs and textures for different applications for both residential and commercial use. "
          />
        </Row>
      </Container>
    </React.Fragment>
  );
};
