import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.style.css";
import { imagesRepoUrl } from "../../config.json";

export const ControlledCarousel = ({ imagesData }) => {
  return (
    <Carousel>
      {imagesData &&
        imagesData.map((itemData) => {
          return (
            <Carousel.Item>
              <img
                className="carousel-img"
                src={`${imagesRepoUrl}${itemData}`}
              />
              <div className="slider-overlay"></div>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};
