import React from "react";
import { EmployeeOverview } from "../../Common/Employee/EmployeeOverview";

export const EmployeeDetails = () => {
  return (
    <React.Fragment>
      <EmployeeOverview />
    </React.Fragment>
  );
};
