import React, { useState, useEffect } from "react";
import { subscriberRegistrationNo } from "../../config.json";
import "../MultiCurrentAffairsDisplay/MultiCurrentAffairsDisplay.css";

export const MultiCurrentAffairsDisplay = ({ affairsData, title }) => {
  const [affairs, setAffairs] = useState([]);
  useEffect(() => {
    setAffairs([...affairsData]);
  }, [affairsData]);
  return (
    <div
      className="video-promo news-section ptb-100 background-img"
      style={{
        backgroundImage: `url(${require("../../img/tech-bg.jpg")})`,
      }}
    >
      <div className="container plr">
        <div className="row">
          <div className="col-12">
            <div className="section-top-padding"></div>
            <div className="title-tech2 text-white">{title}</div>
            <marquee
              scrollamount="2%"
              behavior="scroll"
              direction="down"
              className="m-height"
            >
              {affairs.length > 0 &&
                affairs.map((item) => {
                  return (
                    <p className="news-element">
                      <i className={item.iconPath}></i>
                      <a
                        href={item.relativeUrl}
                        className="linkaffairs"
                        target="blank"
                      >
                        {item.affairsName}
                      </a>
                    </p>
                  );
                })}

              <div className="news-border"></div>
            </marquee>
          </div>
        </div>
      </div>
    </div>
  );
};
