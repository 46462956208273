import React from "react";
import { Dropdown, Container } from "react-bootstrap";
import { SectionTitle } from "../Common/SectionTitle";
// import { AboutParagraph } from "../Common/AboutParagraph";
import { WhyChooseSection } from "../Common/WhyChooseSection";
import { AdmissionDisplay } from "../AdmissionDisplay/AdmissionDisplay";

export const Admission = () => {
  return (
    <React.Fragment>
      <div className="section-top-padding"></div>
      <SectionTitle sectionheading="Admission" />
      <Container>
        <AdmissionDisplay />
      </Container>
    </React.Fragment>
  );
};
